import clone from 'clone';
import { newEstimate, newService } from './config';
import actions from './actions';

const initState = {
  estimates: {},
  services:{},
  savedsuccefull: false,
  isLoading: false,
  errorMessage: false,
  initialEstimates: true,
  currentEstimate: {},
  editableEstimate: {},
  currentService: {},
  editableService: {},
  isNewEstimate: false,
  enableEditView: false,
  previewVisible: false,
  previewImage: '',
  previewTitle: '',
};
export default function reducer(state = initState, { type, ...payload }) 
{
  switch (type) {
    case actions.SELECT_CURRENT_ESTIMATE: {
      const estimates = state.estimates;
      const dataSource = [];
      Object.keys(estimates).map((estimate, index) => {
        return dataSource.push({
          ...estimates[estimate],
        });
      });     
      const index = dataSource.map(estimate =>  estimate.id).indexOf(payload.id);
      const isNewEstimate = index === -1;
      const currentEstimate = isNewEstimate
      ? {
            id: payload.id,
            key: payload.id,
            ...newEstimate,
          }
        : dataSource[index];
        const enableEditView = isNewEstimate;
        return {
        ...state,
        currentEstimate,
        enableEditView,
        isNewEstimate,
        editableEstimate: clone(currentEstimate),
      };
    }
    
    case actions.SELECT_CURRENT_SERVICE: {
      const services = state.services;
      const dataSource = [];
      Object.keys(services).map((service, index) => {
        return dataSource.push({
          ...services[service],
          key: index,
        });
      });     
      const index = dataSource.map(estimate => estimate.SERVICE).indexOf(payload.SERVICE);
      const isNewService = index === -1;
      const currentService = isNewService
      ? {
            id: payload.SERVICE,
            key: payload.SERVICE,
            ...newService,
          }
        : dataSource[index];
        return {
        ...state,
        id: payload.SERVICE,
        currentService,
        editableService: clone(currentService),
      };
    }
    case actions.UPDATE_EDIT_ESTIMATE:
      return {
        ...state,
        savedsuccefull: true,
        isLoading: true,
        editableEstimate: clone(payload.estimate),
      };
    case actions.UPDATE_EDIT_SERVICE:
      return {
        ...state,
        editableService: clone(payload.service),
      };
    case actions.UPDATE_SERVICE:
      return{
        ...state,
        id: payload.SERVICE,
        key: payload.SERVICE,
        currentService: clone(payload.service),
      };
    case actions.UPDATE_ESTIMATE:
      return{
        ...state,
        isLoading: true,
        savedsuccefull: true,
        currentEstimate: clone(payload.estimate),
      };
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: payload.view,
        editableEstimate: clone(state.currentEstimate),
      };
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        savedsuccefull: false,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
      case actions.LOAD_FROM_FIRESTORE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          currentEstimate: false,
          estimates: payload.data,
          errorMessage: false,
        };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Internet Conection Lost',
      };
    case actions.LOAD_FROM_FIRESTORE_SERVICE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
      case actions.LOAD_FROM_FIRESTORE_SERVICE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          services: payload.data,
          errorMessage: false,
        };
    case actions.LOAD_FROM_FIRESTORE_SERVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.LOAD_FROM_FIRESTORE_RECICLE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
      case actions.LOAD_FROM_FIRESTORE_RECICLE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          estimates: payload.data,
          errorMessage: false,
        };
    case actions.LOAD_FROM_FIRESTORE_RECICLE_ERROR:
      return {
        ...state,
        estimates: {},
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        investor: payload.data == null ? initState.investor : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        estimates: payload.data,
      };
    case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    default:
      return state;
  }
}

