import { all, takeLatest, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';

import { rsf, db } from '../../library/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from '../../library/firebase/firebase.util';


const COLLECTION_NAME = 'estimates'; // change your collection
const ORDER_BY = 'id';
const ORDER = 'desc';


function* loadFromFirestore() {
  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where('deleted_at', '==', null)
      .orderBy(ORDER_BY, ORDER)
      .limit(50);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}
function* loadFromFirestoreService() {
  try {
    const collectionRef = db
      .collection('services')
      .where('deleted_at', '==', null)
      .limit(50);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreServiceSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreServiceError(error));
  }
}

function* loadFromFirestoreRecicle() {
  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where('deleted_at', '!=', null)
      .orderBy('deleted_at', ORDER)
      .limit(50);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreRecicleSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreRecicleError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { id, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${id}`,
          {
            deleted_at: new Date().getTime(),
          }
        );
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${id}`,
          {
            ...omit(id, ['id']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, id);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}


function* resetFireStoreDocuments() {
  try {
    yield call(deleteDocuments, COLLECTION_NAME);
  } catch (error) {
    console.log(error);
  }
}

export function* deleteServiceSaga({services}){
  yield call(
    rsf.firestore.setDocument,
    `services/${services['0']}`,
    {
      deleted_at: new Date().getTime(),
    },{ merge: true }
  );
  yield put({type: actions.LOAD_FROM_FIRESTORE_SERVICE})

}

export function* deleteEstimateSaga({estimates}){
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${estimates['0']}`,
    {
      deleted_at: new Date().getTime(),
    },{ merge: true }
  );
  yield put({type: actions.LOAD_FROM_FIRESTORE})

}
export function* RecicleEstimateSaga({estimates}){
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${estimates['0']}`,
    {
      deleted_at: null,
    },{ merge: true }
  );
  yield put({type: actions.LOAD_FROM_FIRESTORE_RECICLE})
}

export function* updateEstimateSaga({ estimates, estimate }) {
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${estimate.id}`,
    estimate
    ,{ merge: true }
  );
  yield put({
    type: actions.UPDATE_ESTIMATE,
    enableEditView: false,
    estimates,
    estimate,
  });
}

export function* updateServiceSaga({ services, service }) {
  yield call(
    rsf.firestore.setDocument,
    `services/${service.SERVICE}`,
    service
    ,{ merge: true }
  );
  yield put({
    type: actions.UPDATE_SERVICE,
    services,
    service,
  });
}

export default function* rootSaga() {
  yield all([
   yield takeLatest(actions.STORE_INTO_FIRESTORE, storeIntoFirestore),
    yield takeLatest(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    yield takeLatest(actions.LOAD_FROM_FIRESTORE_SERVICE, loadFromFirestoreService),
    yield takeLatest(actions.LOAD_FROM_FIRESTORE_RECICLE, loadFromFirestoreRecicle),
    yield takeLatest(actions.UPDATE_ESTIMATE_SAGA, updateEstimateSaga),
    yield takeLatest(actions.UPDATE_SERVICE_SAGA, updateServiceSaga),
    yield takeLatest(actions.DELETE_ESTIMATE_SAGA, deleteEstimateSaga),
    yield takeLatest(actions.DELETE_SERVICE_SAGA, deleteServiceSaga),
    yield takeLatest(actions.RECICLE_ESTIMATE_SAGA, RecicleEstimateSaga)
  ]);
}
