const DOCUMENT = 'CONFIGSITE_';

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
  RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',
  
  STORE_INTO_FIRESTORE: DOCUMENT + 'STORE_INTO_FIRESTORE',

  DELETE_ESTIMATE_SAGA: DOCUMENT + 'DELETE_ESTIMATE_SAGA',
  DELETE_SERVICE_SAGA: DOCUMENT + 'DELETE_SERVICE_SAGA',
  GET_ESTIMATE:  DOCUMENT + 'GET_ESTIMATE',
  UPDATE_ESTIMATE:  DOCUMENT + 'UPDATE_ESTIMATE',
  UPDATE_ESTIMATE_SAGA:  DOCUMENT + 'UPDATE_ESTIMATE_SAGA',

  UPDATE_SERVICE:  DOCUMENT + 'UPDATE_SERVICE',
  UPDATE_SERVICE_SAGA:  DOCUMENT + 'UPDATE_SERVICE_SAGA',
  
  SELECT_CURRENT_ESTIMATE:  DOCUMENT + 'SELECT_CURRENT_ESTIMATE',
  SELECT_CURRENT_SERVICE:  DOCUMENT + 'SELECT_CURRENT_SERVICE',
  TOGGLE_VIEW:  DOCUMENT + 'ESTIMATE_TOGGLE_VIEW',
  UPDATE_EDIT_ESTIMATE:  DOCUMENT + 'ESTIMATE_UPDATE_EDIT_ESTIMATE',
  UPDATE_EDIT_SERVICE: DOCUMENT + 'UPDATE_EDIT_SERVICE',

  RECICLE_ESTIMATE_SAGA: DOCUMENT + 'RECICLE_ESTIMATE_SAGA',
  LOAD_FROM_FIRESTORE_RECICLE: DOCUMENT + 'LOAD_FROM_FIRESTORE_RECICLE',
  LOAD_FROM_FIRESTORE_RECICLE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_RECICLE_SUCCESS',
  LOAD_FROM_FIRESTORE_RECICLE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_RECICLE_ERROR',
  LOAD_FROM_FIRESTORE_SERVICE: DOCUMENT + 'LOAD_FROM_FIRESTORE_SERVICE',
  LOAD_FROM_FIRESTORE_SERVICE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SERVICE_SUCCESS',
  LOAD_FROM_FIRESTORE_SERVICE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_SERVICE_ERROR',

  MONDAY_LOAD: DOCUMENT+ "MONDAY_LOAD",


  mondayload: () => {
    return { type: actions.MONDAY_LOAD };
  },
  
  loadFromFireStoreRecicle: () => {
    return { type: actions.LOAD_FROM_FIRESTORE_RECICLE };
  },

  loadFromFireStoreRecicleSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_RECICLE_SUCCESS,
    data:  data ,
  }),
  
  loadFromFireStoreRecicleError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_RECICLE_ERROR,
    payload: { error },
  }),
  
  loadFromFireStoreService: () => {
    return { type: actions.LOAD_FROM_FIRESTORE_SERVICE };
  },

  loadFromFireStoreServiceSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SERVICE_SUCCESS,
    data:  data ,
  }),
  
  loadFromFireStoreServiceError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_SERVICE_ERROR,
    payload: { error },
  }),

  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE };
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    data:  data ,
  }),
  
  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  storeIntoFirestore: (id , actionName ) =>({
    type: actions.STORE_INTO_FIRESTORE
  }),

  saveIntoFireStore: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: error => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),

  initData: () => ({ type: actions.GET_ESTIMATE }),

  deleteEstimate: selected => {
    return (dispatch, getState) => {

      dispatch({
        type: actions.DELETE_ESTIMATE_SAGA,
        estimates: selected,
      });
    };
  },

  deleteService: selected => {
    return (dispatch, getState) => {

      dispatch({
        type: actions.DELETE_SERVICE_SAGA,
        services: selected,
      });
    };
  },

  RecicleEstimate: selected => {
    return (dispatch, getState) => {

      dispatch({
        type: actions.RECICLE_ESTIMATE_SAGA,
        estimates: selected,
      });
    };
  },

  updateEstimate: estimate => {
    return (dispatch, getState) => {
      let ESTIMATE = getState().Estimates.estimates;
      const estimates = Object.keys(ESTIMATE).map((key) => [Number(key), ESTIMATE[key]]);
      const index = estimates.map(inv => inv.id).indexOf(estimate.id);
      if (index === -1) {
        estimates.push(estimate);
      } else {
        estimates[index] = estimate;
      }
      dispatch({
        type: actions.UPDATE_ESTIMATE_SAGA,
        estimates,
        estimate,
      });
    };
  },

  updateService: service => {
    return (dispatch, getState) => {
      let SERVICE = getState().Estimates.services;
      const services = Object.keys(SERVICE).map((key) => [Number(key), SERVICE[key]]);
      const index = services.map(inv => inv.SERVICE).indexOf(service.SERVICE);
      if (index === -1) {
        services.push(service);
      } else {
        services[index] = service;
      }
      dispatch({
        type: actions.UPDATE_SERVICE_SAGA,
        services,
        service,
      });
    };
  },
  

  selectCurrentEstimate: id => ({ type: actions.SELECT_CURRENT_ESTIMATE, id }),

  selectCurrentService: SERVICE => ({ type: actions.SELECT_CURRENT_SERVICE, SERVICE }),
  
  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }), 
  
  editEstimate: estimate => ({ type: actions.UPDATE_EDIT_ESTIMATE, estimate }),

  editService: service => ({ type: actions.UPDATE_EDIT_SERVICE, service }),
};
export default actions;
