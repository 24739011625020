import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import ReduxSagaFirebase from "redux-saga-firebase";
const firebaseConfig = {
  apiKey: "AIzaSyCMCLI_7T6ChKJNTokC9J65_qWTR10ugjQ",
  authDomain: "wideroofing.firebaseapp.com",
  projectId: "wideroofing",
  storageBucket: "wideroofing.appspot.com",
  messagingSenderId: "673833256187",
  appId: "1:673833256187:web:259a67edded4180c121c5e",
  measurementId: "G-Y1E5KXH6SZ",
};
/*
const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};
 const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
 !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
*/

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const rsf = new ReduxSagaFirebase(firebaseApp);
export default firebase;
